import { Query } from "@fscrypto/domain/query";
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";
import React from "react";

interface ParameterInputProps {
  parameter: Query["parameters"][number];
  ephemeralValue: string | undefined;
  setEphemeralValues: (name: string, value: string) => void;
  className?: string;
}

export const ParameterInput: React.FC<ParameterInputProps> = ({
  parameter,
  ephemeralValue,
  setEphemeralValues,
  className,
}) => {
  return (
    <div
      key={parameter.id}
      onDragStart={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseMove={(e) => e.stopPropagation()}
    >
      {parameter.type === "list" ? (
        <div className="flex items-center">
          <div className="text-sm text-content/80 bg-muted h-9 flex items-center px-3 border rounded-md py-1 mr-px">
            {parameter.name}
          </div>
          <Select
            onValueChange={(val) => setEphemeralValues(parameter.name, val)}
            // value={ephemeralValue !== undefined ? ephemeralValue : parameter.value}
            defaultValue={parameter.value}
          >
            <SelectTrigger className="w-full ml-0 rounded-l-0">
              <SelectValue placeholder={parameter.name} />
            </SelectTrigger>
            <SelectContent>
              {parameter.restrictedValues?.split(",").map((val) => (
                <SelectItem value={val.trim()} key={val}>
                  {val.trim()}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      ) : (
        <Input
          defaultValue={parameter.value}
          onChange={(e) => setEphemeralValues(parameter.name, e.target.value)}
          inputClassName={"bg-background w-24"}
          className={className}
          startAddon={parameter.name}
        />
      )}
    </div>
  );
};
